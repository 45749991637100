jQuery(document).ready(($) => {
  if($(window).width() > 640) {
    select($);
  }
  else {
    $('.sel').each(function () {
      $(this).removeClass('sel');
    });
    fallbackSelect($);
  }
});

// FUNCTIONS
const ajaxHandler = () => {
  const data = {
    action: 'submit_form',
    afp_nonce: afp_vars.afp_nonce
  }

  if ($.query.get('select-main_type')) {
    data.mainType = $.query.get('select-main_type');
  }
  if ($.query.get('select-country')) {
    data.country = $.query.get('select-country');
  }

  $.ajax({
    data: data,
    type: 'post',
    dataType: 'html',
    url: afp_vars.afp_ajax_url,
    beforeSend: function () {
      $('#wines').empty();
      $('#loader-container').addClass('show');
    },
    complete: function () {
      $('#loader-container').removeClass('show');
    },
    success: function (data) {
      $('#wines').html(data).foundation();
    },
    error: function (MLHttpRequest, textStatus, errorThrown) {
      console.log(MLHttpRequest);
      console.log(textStatus);
      console.log(errorThrown);
    }
  });
};

const itemClicked = function (item = false, cat = false, slug = false) {
  if(item) {
    const $this = $(item);
    const txt = $this.text();
    const index = $this.index();

    $this.siblings('.sel__box__options').removeClass('selected');
    $this.addClass('selected');

    const $currentSel = $this.closest('.sel');
    $currentSel.children('.sel__placeholder').text(txt);
    $currentSel.children('select').prop('selectedIndex', index + 1);
  }

  if (!cat || !slug) { return; }

  if ($('body.archive').length && cat || slug) {
    if (history.pushState) {
      window.history.pushState({}, null, $.query.SET(cat, slug));
      ajaxHandler();
    }
    else {
      document.location = '/tenders' + $.query.SET(cat, slug);
    }
  }
};

// FUNCTIONS END

const fallbackSelect = ($) => {
  if ($('select.postform').length || $('.tender-choose').length) {
    if (!$('body.home').length) {
      $('select.postform').on('change', function (e) {
        const $this = $(this);
        const cat = 'select-' + $this.attr('id');
        const slug = ($this.find(':selected').val() !== 'select-0' ? $this.find(':selected').val() : '');
        if ($('body.archive').length && cat || slug) {
          if (history.pushState) {
            window.history.pushState({}, null, $.query.SET(cat, slug));
            ajaxHandler();
          }
          else {
            document.location = '/tenders' + $.query.SET(cat, slug);
          }
        }
      });
    }
  }
};

const select = ($) => {
  /* ===== Logic for creating fake Select Boxes ===== */
  $('.sel').each(function () {
    const $current = $(this);
    $current.children('select').css('display', 'none');

    const selectedType = $.query.get('select-' + $current.find('select').attr('id'));
    $current.find('option').each(function (i, el) {
      let selected = false;
      if (selectedType === $(el).val())  {
        selected = true;
      }
      if (i == 0) {
        $current.prepend($('<div>', {
          class: $current.attr('class').replace(/sel/g, 'sel__box'),
          id: 'select-' + $current.find('select').attr('id')
        }));

        const placeholder = $(this).text();
        $current.prepend($('<span>', {
          class: $current.attr('class').replace(/sel/g, 'sel__placeholder'),
          text: placeholder,
          'data-placeholder': placeholder,
        }));

        $current.children('div').append($('<span>', {
          class: $current.attr('class').replace(/sel/g, 'sel__box__options'),
          text: $(this).text(),
          id: 'select-' + $(this).attr('value'),
          selected: selected
        }));
        return;
      }
      else {

      }
      $current.children('div').append($('<span>', {
        class: $current.attr('class').replace(/sel/g, 'sel__box__options'),
        text: $(this).text(),
        id: $(this).attr('value'),
        selected: selected
      }));
    });
    const cat = $current.find('select').attr('id');
    // const slug = $('#' + cat).find(':selected').val();
    if(selectedType) {
      itemClicked($('#select-' + cat).find('#' + selectedType));
    }
    // itemClicked();


  });

  // Toggling the `.active` state on the `.sel`.
  $('.sel').click(function() {
    $(this).toggleClass('active');
  });

  $(document).click(function (e) {
    e.stopPropagation();
    const $container = $(".categories > div");

    //check if the clicked area is dropDown or not
    if ($container.has(e.target).length === 0) {
      $container.find('.sel').removeClass('active');
    }
  });

  // Toggling the `.selected` state on the options.
  if($('body.home').length) {
    $('.sel__box__options').click(function () {
      const $this = $(this);
      const cat = $this.closest('.sel__box').attr('id');
      const slug = ($this.attr('id') !== 'select-0' ? $this.attr('id') : '0');
      itemClicked(this, cat, slug);
    });
  }
  else {
    $('.sel__box__options').click(function () {
      const $this = $(this);
      const cat = $this.closest('.sel__box').attr('id');
      const slug = ($this.attr('id') !== 'select-0' ? $this.attr('id') : '0');
      itemClicked(this, cat, slug);
    });
  }

  $('#go-tenders').on('click', function (e) {
    document.location = '/tenders' + location.search;
  });
};